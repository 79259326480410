
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/indicator/Overview.vue";
import Basic from "@/view/pages/resources/documentation/base/indicator/Basic.vue";
import Toggle from "@/view/pages/resources/documentation/base/indicator/Toggle.vue";

export default defineComponent({
  name: "indicator",
  components: {
    Overview,
    Basic,
    Toggle,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Indicator");
    });
  },
});
